import { useEffect, useState } from 'react'

export default function App() {
    if (localStorage.getItem("count") === null) {
        localStorage.setItem("count", 0)
    }
    const [count, setCount] = useState(localStorage.getItem('count'));

    useEffect(() => {
      localStorage.setItem('count', count);
    }, [count]);
  
    const changeCount = () => {
      let tempCount = parseInt(count)  
      setCount(tempCount + 1);
    };
    return (
        <main>
            <p id="count">{count}</p>
            <button onClick={changeCount}>Change</button>
        </main>
    )
}